<template>
  <div class="main">
    <div class="textbox" style="padding: 30px 0 0 10px">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/PolicyInformation' }"
          >政策资讯</el-breadcrumb-item
        >
        <el-breadcrumb-item>资讯详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- body start -->
    <div class="bodybox" v-if="Obj.State">
      <div class="textbox">
        <!-- 新闻资讯 -->
        <div>
          <div class="textbox">
            <div class="leftbox">
              <div class="syncbox">
                <div class="boxtitle">{{ Obj.Title }}</div>
                <div
                  class="article-author"
                  v-if="
                    Obj.CateName != '产业政策' || Obj.CateName != '优惠政策'
                  "
                >
                  <span>{{ Obj.PublishTime }}</span>
                  <span>{{ Obj.CateName }}</span>
                </div>
              </div>
              <div
                class="tabelbox"
                v-if="Obj.CateName == '产业政策' || Obj.CateName == '优惠政策'"
              >
                <div class="fisttab">
                  <div class="tabtitle">关键字:</div>
                  <div class="tabtext">
                    {{ Obj.NewsKey }}
                  </div>
                </div>
                <div class="second">
                  <div class="minboxl">
                    <div class="mintitle">政策级别:</div>
                    <div class="mintext">{{ Obj.PolicyLevelName }}</div>
                  </div>
                  <div class="minbox">
                    <div class="mintitle">发布时间:</div>
                    <div class="mintext">{{ Obj.PublishTime }}</div>
                  </div>
                </div>
                <div class="second">
                  <div class="minboxl">
                    <div class="mintitle">产业:</div>
                    <div class="mintext">{{ Obj.SaleTypeName }}</div>
                  </div>
                  <div class="minbox">
                    <div class="mintitle">项目:</div>
                    <div class="mintext">{{ Obj.ProjectName }}</div>
                  </div>
                </div>

                <div class="second">
                  <div class="minboxl">
                    <div class="mintitle">申报开始时间:</div>
                    <div class="mintext">{{ Obj.StarTime }}</div>
                  </div>
                  <div class="minbox">
                    <div class="mintitle">申报结束时间:</div>
                    <div class="mintext">{{ Obj.EndTime }}</div>
                  </div>
                </div>
              </div>
              <div
                class="htmlbox"
                v-if="Obj.NewsContent"
                v-html="Obj.NewsContent"
                 ref="content"
          @click="imageEnlargement"
              ></div>

              <div class="article-files" v-if="FileList.length">
                <div class="title">附件：</div>
                <ul>
                  <li v-for="(item, i) in FileList" :key="i">
                    <a
                      @click="downfile(item.url, item.name)"
                      href="javascript:void(0)"
                      v-if="item.size === -1"
                    >
                      {{ item.name }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="rightbox">
              <el-card
                style="
                  margin-bottom: 30px;
                  background-color: #f9f9f9;
                  border: none;
                  box-shadow: none;
                "
                class="box-card"
              >
                <h4>最新资讯</h4>
                <div
                  v-for="(item, i) in NewNewsList"
                  :key="i"
                  class="items"
                  style="cursor: pointer"
                  @click="InformationDetails(item)"
                >
                  <span
                    style="font-size: 20px; font-weight: 800; color: #366cb3"
                    >·</span
                  >
                  <span
                    style="
                      width: 200px;
                      height: 32px;
                      text-overflow: -o-ellipsis-lastline;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      line-clamp: 1;
                      -webkit-box-orient: vertical;
                    "
                    >{{ item.Title }}</span
                  >
                  <i>{{ item.PublishTime }}</i>
                </div>
              </el-card>

              <el-card
                style="
                  margin-bottom: 10px;
                  background-color: #f9f9f9;
                  border: none;
                  box-shadow: none;
                "
                class="box-card"
              >
                <h4>最新注册企业</h4>
                <div
                  v-for="(item, i) in NewCompanyList"
                  :key="i"
                  class="items"
                  @click="getComInfor(item.CompanySysID)"
                  style="cursor: pointer"
                >
                  <span
                    style="
                      font-size: 20px;
                      font-weight: 800;
                      color: #366cb3;
                      padding-right: 3px;
                    "
                    >·</span
                  >
                  {{ item.CName }}
                </div>
              </el-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- body end -->
  </div>
</template>

<script>
// 公共样式

import common from "../util/common.js";
import timeFor from "../util/time.js";
import "../assets/css/common.css";
export default {
  data() {
    return {
      activeIndex: "2",
      drawer: false,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      navlist: [
        {
          names: "首页",
          path: "/",
          id: "1",
        },
        {
          names: "政策资讯",
          path: "/news",
          id: "2",
        },
        {
          names: "产业载体",
          path: "/carrier",
          id: "3",
        },
        {
          names: "企业互动",
          path: "/interaction",
          id: "4",
        },
        {
          names: "金融服务",
          path: "/service",
          id: "5",
        },
        {
          names: "企业服务",
          path: "/enterpriseServices",
          id: "6",
        },
        {
          names: "招聘服务",
          path: "/recruit",
          id: "7",
        },
      ],
      arr: ["zt1", "zt2", "zt3", "zt4", "zt5", "zt6", "zt7", "zt8"],
      aa: ["不限", "周末双休", "五险一金", "包吃住", "带薪年假"],
      indexaa: 0,
      bb: ["不限", "综合", "电商", "文化创意", "互联网"],
      indexbb: 0,
      height: "400px",
      input2: "",
      Obj: {},
      NewNewsList: [],
      NewCompanyList: [],
      FileList: [],
       imgArr:[],
    };
  },

  watch: {
    windowWidth(val) {
      let that = this;
      // console.log("实时屏幕宽度：", val);
      if (val > 990) {
        that.drawer = false;
        that.height = "400px";
      } else if (val <= 900 && val > 400) {
        that.height = "200px";
      } else {
        that.height = "100px";
      }
    },
  },
  computed: {
    //onRoutes为菜单元素中的属性，:defalut-active="onRoutes" 对应的值

    onRoutes() {
      return "/PolicyInformation";
    },
  },
  methods: {
     imageEnlargement(e) {
   
      if (e.target.nodeName == "IMG") {
        //判断点击富文本内容为img图片
        console.log(e.target.currentSrc);
        this.imgArr[0] = e.target.currentSrc;
        console.log(this.imgArr);

         this.$viewerApi({
          images: this.imgArr
        })
     
      } else {
        console.log("点击内容不为img");
      }
    },

    //   企业详情
    getComInfor(id) {
      this.$router.push({ path: "/enterpriseDetails", query: { SysID: id } });
    },
    InformationDetails(item) {
      let type = item.Type;
      console.log(type);
      if (type == 1) {
        this.$router.push({
          path: "/ZMBpolicyDetail",
          query: { SysID: item.SysID },
        });
      } else {
        this.GetNewsDetail(item.SysID);
      }
    },
    GetNewsDetail(id) {
      let that = this;
      that
        .$post("/api/News/ObjShow", {
          SysID: id,
        })
        .then(function (data) {
          console.log(data);
          if (data.Result) {
            let obj = data.Obj.Obj;

            if (!obj.State) {
              that.$alert("该资讯已下架，请查看其他信息", {
                confirmButtonText: "确定",
                callback: () => {
                  that.$router.go(-1);
                },
              });

              return;
            }
            let arr = obj.Tags;
            if (obj.Tags != null && obj.Tags.length > 0) {
              obj.Tags = arr.split(",");
            }

            obj.PublishTime = timeFor.timeFormat(obj.PublishTime, 1);
            obj.CreateOn = timeFor.timeFormat(obj.CreateOn, 1);
            obj.StarTime = timeFor.timeFormat(obj.StarTime, 1);
            obj.EndTime = timeFor.timeFormat(obj.EndTime, 1);
            if (data.Obj.FileList != null) {
              data.Obj.FileList.forEach((i) => {
                that.FileList.push({
                  name: i["SourceName"] + "." + i["Extension"],
                  url: common.GetFileHref(i["URL"], i["Extension"]),
                  size: -1,
                  SysID: i.SysID,
                  Extension: i.Extension,
                });
              });
            }
            that.Obj = obj;
            window.scroll(0, 0);
          } else {
            that.$alert("该资讯已下架，请查看其他信息", {
              confirmButtonText: "确定",
              callback: () => {
                that.$router.go(-1);
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    choeseaa(index) {
      console.log(index);
      this.indexaa = index;
    },
    choesebb(index) {
      console.log(index);
      this.indexbb = index;
    },
    handleSelect(e) {
      let that = this;
      console.log(e);
      that.$router.push(e);
      setTimeout(() => {
        that.drawer = false;
      }, 500);
    },
    getlogin() {
      this.$router.push("/login");
    },
    register() {
      this.$router.push("/register");
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    downfile(fileurl, name) {
      common.LocalDownloadFileEdit(this.urlOA + fileurl, name);
    },
    GetNewNewsList() {
      let that = this;
      that
        .$post("/api/News/List", {
          State: true,
          Sort: "CreateOn desc",
          PageSize: 8,
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data);
          that.NewNewsList = data.Obj;
          that.NewNewsList.forEach((v) => {
            v.FirstImgUrl = that.urlOA + v.FirstImgUrl.substr(1);
            v.PublishTime = timeFor.timeFormat(v.PublishTime, 3);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetNewCompanyList() {
      let that = this;
      that
        .$post("/api/UserInfo/List", {
          State: true,
          CState: 1,
          Sort: "CreateOn desc",
          PageSize: 8,
          Type: 1,
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data);
          that.NewCompanyList = data.Obj;
          that.NewCompanyList.forEach((v) => {
            v.CompanyLogo = that.urlOA + v.CompanyLogo.substr(1);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    console.log(this.urlOA);
    let SysID = this.$route.query.SysID;

    console.log(SysID);
    var that = this;
    that.GetNewNewsList();
    that.GetNewCompanyList();
    that.GetNewsDetail(SysID);
    let windowWidth = that.windowWidth;
    console.log(windowWidth);
    if (windowWidth < 900 && windowWidth > 400) {
      that.height = "200px";
    } else if (windowWidth < 400) {
      that.height = "150px";
    }
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight; // 高
        that.windowWidth = window.fullWidth; // 宽
      })();
    };
  },
};
</script>


<style scoped  lang="scss">
.tabelbox {
  margin-left: 10px;
  margin-top: 20px;
  .fisttab {
    width: 860px;
    display: flex;
    .tabtitle {
      flex: 2;
      padding: 8px;
      background-color: #f7f7f7;
      font-size: 14px;
      box-sizing: border-box;
      color: #999999;
    }
    .tabtext {
      flex: 8;
      padding: 8px;
      background-color: #f7f7f7;
      font-size: 14px;
      box-sizing: border-box;
      margin-left: 3px;
      color: #3273dc;
    }
  }
  .second {
    width: 860px;
    display: flex;
    margin-top: 3px;
    .minboxl {
      flex: 1;
      display: flex;

      font-size: 14px;
      .mintitle {
        flex: 3;
        background-color: #f7f7f7;
        padding: 8px;
        color: #999999;
      }
      .mintext {
        flex: 7;
        background-color: #f7f7f7;
        padding: 8px;
        margin-left: 3px;
        color: #3273dc;
      }
    }
    .minbox {
      flex: 1;

      display: flex;

      font-size: 14px;
      margin-left: 3px;
      .mintitle {
        flex: 3;
        background-color: #f7f7f7;
        padding: 8px;
        color: #999999;
      }
      .mintext {
        background-color: #f7f7f7;
        padding: 8px;
        flex: 7;
        margin-left: 3px;
        color: #3273dc;
      }
    }
  }
}

// 内容区
.article-files {
  background-color: #f9f9f9;
  width: 860px;
  padding: 10px 0;
  margin-left: 10px;
  margin-bottom: 30px;
  .title {
    font-size: 18px;
    font-weight: 600;
    color: #3273dc;
    box-sizing: border-box;
    padding-left: 20px;
  }
  ul {
    padding: 20px;
    box-sizing: border-box;
    li {
      a {
        color: #555;
      }
    }
  }
}
.textbox {
  width: 1230px;
  margin-left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  //   background-color: #475669;
  // padding-top: 50px;
  overflow: hidden;
  .leftbox {
    float: left;
    min-height: 600px;
    width: 900px;
    .synctitle {
      width: 100%;
      height: 22px;
      line-height: 22px;
      padding-left: 10px;
      font-size: 22px;
      font-weight: 500;
      border-left: 4px solid #3273dc;
      box-sizing: border-box;
      margin-bottom: 20px;
    }
    .syncbox {
      position: relative;
      width: 100%;
      padding: 0 6px;
      box-sizing: border-box;
      .boxtitle {
        font-size: 24px;
        font-weight: 700;
      }
      .boxtags {
        width: 80%;
        padding: 10px 0;
        // box-sizing: border-box;
        overflow: hidden;
        span {
          float: left;
          padding: 2px 10px;
          background-color: #ed982c;
          color: #fff;
          font-size: 14px;
          border-radius: 2px;
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
      .box_btn {
        position: absolute;
        bottom: 6px;
        right: 10px;
        padding: 10px 30px;
        background-color: #3fbcbb;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
      }
      .article-author {
        height: 60px;
        line-height: 60px;
        span {
          padding: 3px 10px;
          margin-right: 10px;
          background-color: #ff6633;
          color: #fff;
          font-size: 14px;
        }
      }
    }
    .htmlbox {
      width: 830px;
      margin-top: 20px;
      margin-left: 10px;
      margin-bottom: 30px;
      padding: 20px;
      border-radius: 5px;
      background-color: #fff;
      min-height: 100px;
      // box-shadow: 0px 0px 20px 0 rgb(105 105 105 / 30%);
      ::v-deep p {
        line-height: 30px;
        font-size: 15px;
        color: #555;
      }
      ::v-deep li {
        line-height: 30px;
        font-size: 15px;
        color: #555;
      }
      ::v-deep img {
        max-width: 100%;
      }
    }
    .combox {
      width: 860px;
      margin-top: 20px;
      margin-left: 10px;
      padding: 20px;
      border-radius: 5px;
      background-color: #fff;
      box-shadow: 0px 0px 20px 0 rgb(105 105 105 / 30%);
      margin-bottom: 100px;
      overflow: hidden;
      img {
        float: left;
        cursor: pointer;
        height: 40px;
      }
      span {
        float: left;
        height: 40px;
        cursor: pointer;
        line-height: 40px;
        font-size: 20px;
        margin-left: 20px;
        font-weight: 700;
      }
    }
  }
  .rightbox {
    float: left;
    width: 320px;
    margin-left: 10px;

    .rightbox::v-deep .el-card {
      box-shadow: none;
    }
    h4 {
      border-left: 4px solid #3273dc;
      padding-left: 20px;
      box-sizing: border-box;
    }
    .items {
      font-size: 0.8rem;
      line-height: 36px;
      height: 36px;

      overflow: hidden;

      span {
        float: left;

        line-height: 32px;
        height: 32px;
        margin-left: 3px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      i {
        float: right;
        font-style: normal;
      }
    }
  }
}
.othernews {
  margin-top: 10px;
  height: 30px;
  width: 260px;
  margin-left: 20px;
  //   line-height: 30px;
  border-top: 1px solid #eee;
  font-size: 14px;
  //   padding-left: 10px;
  box-sizing: border-box;
  cursor: pointer;
}
.othernews:hover {
  color: #3273dc;
}
.othernews span {
  display: inline-block;
  height: 30px;
  padding-right: 10px;
  //   padding-top: 10px;
  color: #3273dc;
  font-size: 24px;
  font-weight: 800;
  //   line-height: 30px;
  box-sizing: border-box;
}
.comlist {
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  .imgbox {
    float: left;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .inforbox {
    float: left;
    margin-left: 10px;
    width: 200px;

    .comnames {
      height: 30px;
      line-height: 30px;
      font-size: 16px;
    }
    .money {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      color: #999;
    }
  }
}
.htmlbox >>> ul li {
  line-height: 100px;
}
.aaa {
  display: none;
}

@media (max-width: 991px) {
  .aaa {
    display: block;
  }

  .bannerbox img {
    height: 100%;
  }
  .main .textbox {
    width: 100%;
    overflow: hidden;
    padding-top: 10px;
  }
}
@media (max-width: 500px) {
  .tabelbox {
    padding: 0 10px;
    margin-left: 0;
    margin-top: 20px;
    .fisttab {
      width: 100%;

      display: flex;
      .tabtitle {
        flex: 2;
        padding: 8px;
        background-color: #f7f7f7;
        font-size: 14px;
        box-sizing: border-box;
        color: #999999;
      }
      .tabtext {
        flex: 8;
        padding: 8px;
        background-color: #f7f7f7;
        font-size: 14px;
        box-sizing: border-box;
        margin-left: 3px;
        color: #3273dc;
      }
    }
    .second {
      width: 100%;
      display: flex;
      margin-top: 3px;
      .minboxl {
        flex: 1;
        display: flex;

        font-size: 14px;
        .mintitle {
          flex: 5;
          background-color: #f7f7f7;
          padding: 8px;
          color: #999999;
        }
        .mintext {
          flex: 7;
          background-color: #f7f7f7;
          padding: 8px;
          margin-left: 3px;
          color: #3273dc;
        }
      }
      .minbox {
        flex: 1;

        display: flex;

        font-size: 14px;
        margin-left: 3px;
        .mintitle {
          flex: 5;
          background-color: #f7f7f7;
          padding: 8px;
          color: #999999;
        }
        .mintext {
          background-color: #f7f7f7;
          padding: 8px;
          flex: 7;
          margin-left: 3px;
          color: #3273dc;
        }
      }
    }
  }

  .textbox {
    width: 100%;
    padding-top: 0;
    .leftbox {
      width: 100%;
      min-height: 100px;
      .synctitle {
        width: 100%;
        height: 22px;
        line-height: 22px;
        padding-left: 10px;
        font-size: 22px;
        font-weight: 500;
        border-left: 4px solid #3273dc;
        box-sizing: border-box;
        margin-bottom: 20px;
      }
      .syncbox {
        position: relative;
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
        .boxtitle {
          font-size: 18px;
          font-weight: 550;
        }
        .boxtags {
          width: 80%;
          padding: 10px 0;
          // box-sizing: border-box;
          overflow: hidden;
          span {
            float: left;
            padding: 2px 10px;
            background-color: #ed982c;
            color: #fff;
            font-size: 14px;
            border-radius: 2px;
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }
        .box_btn {
          position: absolute;
          bottom: 6px;
          right: 10px;
          padding: 10px 30px;
          background-color: #3fbcbb;
          border-radius: 4px;
          color: #fff;
          cursor: pointer;
        }
        .article-author {
          height: 60px;
          line-height: 60px;
          span {
            padding: 3px 10px;
            margin-right: 10px;
            background-color: #ff6633;
            color: #fff;
            font-size: 14px;
          }
        }
      }
      .htmlbox {
        width: 96%;
        margin-top: 20px;
        margin-left: 2%;
        margin-bottom: 30px;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: #fff;
        box-shadow: 0px 0px 20px 0 rgb(105 105 105 / 30%);
        ::v-deep p {
          line-height: 30px;
          font-size: 15px;
          color: #555;
        }
        ::v-deep li {
          line-height: 30px;
          font-size: 15px;
          color: #555;
        }
        ::v-deep img {
          max-width: 100%;
        }
      }
    }
  }

  .article-files {
    background-color: #f9f9f9;
    width: 96%;
    padding: 10px;
    margin-left: 2%;
    margin-bottom: 10px;
    box-sizing: border-box;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: #3273dc;
      box-sizing: border-box;
      padding-left: 0;
    }
    ul {
      padding: 20px 20px 20px 0;
      box-sizing: border-box;
      li {
        a {
          color: #555;
          font-size: 14px;
        }
      }
    }
  }
  .textbox .rightbox {
    float: left;
    width: 96%;
    margin-left: 2%;
    margin-top: 20px;
  }
}
</style>